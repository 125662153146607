import React, { useState } from "react";
import { useCoins } from "../../CoinsContext"; 
import styles from "./subscribe.module.scss";
import { Col, Row } from "antd";
import instagram from "./assets/instagram.png";
import telegram from "./assets/telegram.webp";
import x from "./assets/x.png";
import ParticlesBackground from "../../ParticlesBackground";

const Subscribe: React.FC = () => {
  const { addCoins } = useCoins(); 

  const [items, setItems] = useState([
    { id: 1, title: "عضو کانال تلگرام ما شوید", coins: 100, link: "https://t.me/yourchannel", icon: telegram },
    { id: 2, title: "صفحه اینستاگرام ما را دنبال کنید", coins: 100, link: "https://instagram.com/yourprofile", icon: instagram },
    { id: 3, title: "عضو چنل توییتر ما شوید", coins: 100, link: "https://twitter.com/yourprofile", icon: x },
  ]);

  const handleFollow = (coins: number, link: string, id: number) => {
    window.open(link, "_blank"); 
    addCoins(coins); 
    setItems(prevItems => prevItems.filter(item => item.id !== id)); 
  };

  return (
    <div className={styles.div1}>
      <ParticlesBackground />
      {items.map((item) => (
        <div key={item.id} className={styles.Row}>
          <Row>
            <Col span={2}>
              <img src={item.icon} alt="" className={styles.icon} />
            </Col>
            <Col span={16}>
              <Row>
                <h5 className={styles.title}>{item.title}</h5>
              </Row>
              <Row>
                <h5 className={styles.title2}>${item.coins}</h5>
              </Row>
            </Col>
            <Col span={6}>
              <button 
                className={styles.btn} 
                onClick={() => handleFollow(item.coins, item.link, item.id)}>
                دنبال کردن
              </button>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default Subscribe;
