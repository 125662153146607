import React, { useEffect, useState } from "react";
import { useCoins } from "../../CoinsContext";
import ParticlesBackground from "../../ParticlesBackground";
import styles from "./coin.module.scss";

interface CoinCounterProps {
  logoUrl: string;
  adVideos: string[]; // لیستی از URLهای ویدیوهای تبلیغاتی
}

const CoinCounter: React.FC<CoinCounterProps> = ({ logoUrl, adVideos }) => {
  const { coins, incrementCoins } = useCoins();
  const discount = Math.min(Math.floor(coins / 10), 50);
  const [showAd, setShowAd] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(16); // زمان ویدیو
  const [showIncrement, setShowIncrement] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0); // شاخص ویدیوی فعلی

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  const handleClick = () => {
    if (canClick && discount < 50) {
      incrementCoins(1);
      setShowIncrement(true);
      setCanClick(false);
      setTimeout(() => {
        setShowIncrement(false);
      }, 1000);

      setTimeout(() => {
        setCanClick(true);
      }, 250);
    }
  };

  useEffect(() => {
    if (coins > 0 && coins % 10 === 0) {
      setShowAd(true);
      setTimer(16); // ریست کردن تایمر
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setShowAd(false);
            setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % adVideos.length); // تغییر ویدیو پس از پخش
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [coins]);

  const handleAdClick = () => {
    setShowAd(false);
  };

  return (
    <div className={styles.div}>
      <ParticlesBackground />

      <div className={styles.img}>
        {isMobile ? (
          <div className={styles.logoContainer}>
            <div className={styles.niasMask}>
              <img
                onClick={handleClick}
                src={logoUrl}
                alt="Site Logo"
                className={styles.logo}
              />
              {showIncrement && <span className={styles.increment}>+1</span>}
            </div>
          </div>
        ) : (
          <h2>لطفا با تلفن همراه وارد شوید</h2>
        )}
        <div className={styles.div2}>
          <h1 className={styles.coin}>{coins} سکه</h1>
          <h2 className={styles.title}>تخفیف شما: {discount}%</h2>
          <div className={styles.progressBar}>
            <div
              className={styles.progressFill}
              style={{ width: `${discount * 2}%` }}
            />
          </div>
        </div>
        {showAd && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.8)",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div className={styles.timer}>
              {timer > 0 ? (
                <h3>{timer} ثانیه باقی مانده</h3>
              ) : (
                <h3
                  onClick={handleAdClick}
                  style={{
                    color: "grey",
                    fontSize: "24px",
                    cursor: "pointer",
                  }}
                >
                  ✖
                </h3>
              )}
            </div>
            {/* <h2>تبلیغ!</h2>
            <p>شما {coins} سکه جمع آوری کرده‌اید!</p>
            <p>تبلیغ در حال نمایش است...</p> */}
            <video
              src={adVideos[currentVideoIndex]}
              controls
              autoPlay
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // پوشاندن کل صفحه
              }}
              preload="auto" // ویدیو فقط در هنگام پخش دانلود می‌شود
              onEnded={() => {
                setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % adVideos.length);
                setTimer(16); // زمان را برای ویدیو بعدی ریست کنید
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinCounter;
