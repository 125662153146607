import React from "react";
import { useCoins } from "../../CoinsContext"; // دریافت context سکه‌ها
import { Col, Row } from "antd";
import ParticlesBackground from "../../ParticlesBackground";
import styles from "./discountCode.module.scss";

interface DiscountCodeProps {
  vector: string; // ورودی برای تصویر از App
}

const DiscountCode: React.FC<DiscountCodeProps> = ({ vector }) => {
  const { coins } = useCoins(); // دریافت تعداد سکه‌ها از context
  const discount = Math.floor(coins / 10); // محاسبه درصد تخفیف
  const [discountCode, setDiscountCode] = React.useState<string | null>(null);

  const handleApplyDiscount = async () => {
    try {
      const response = await fetch(
        "https://shoplines.ir/wp-json/custom/v1/discount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer <your-auth-token>",
            "X-WP-Nonce": "<your-nonce>",
          },
          body: JSON.stringify({
            discountCode: discountCode,
            discount: discount,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setDiscountCode(data.code);
    } catch (error) {
      console.error("Failed to apply discount:", error);
      alert("خطا در اعمال تخفیف، لطفا دوباره تلاش کنید.");
    }
  };

  return (
    <div className={styles.div1}>
      <ParticlesBackground />
      <div className={styles.div2}>
        <Row>
          <Col span={12}>
            <h5 className={styles.title}> مقدار سکه‌های جمع شده:</h5>
          </Col>
          <Col span={12}>
            <h5 className={styles.title2}>{coins} سکه</h5>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <h5 className={styles.title}> مقدار تخفیف:</h5>
          </Col>
          <Col span={12}>
            <h5 className={styles.title2}>{discount}%</h5>
          </Col>
        </Row>
      </div>
      <div className={styles.discount}>
        {discountCode ? <h3>کد تخفیف شما: {discountCode}</h3> : null}
      </div>
      <Row>
        <div className={styles.div3}>
          <button onClick={handleApplyDiscount} className={styles.button}>
            اعمال تخفیف
          </button>
        </div>
        {discountCode && <h3>کد تخفیف شما: {discountCode}</h3>}
      </Row>
      {/* استفاده از ورودی تصویر به جای تصویر ثابت */}
      <img src={vector} alt="vector" className={styles.img} />
    </div>
  );
};

export default DiscountCode;
