import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import CoinCounter from './component/coinCounter/coinCounter';
import DiscountCode from './component/discountCode/discountCode';
import Subscribe from './component/subscribe/subscribe';
import logo from './assets/Component 9.png';
import imgVector from './component/discountCode/assets/vector.png'
import './App.css';
import { FaCoins, FaTag, FaUser } from 'react-icons/fa'; // اضافه کردن آیکون‌ها
import { CoinsProvider } from './CoinsContext'; // اضافه کردن CoinsProvider
import ParticlesBackground from "./ParticlesBackground";
const App: React.FC = () => {
  const adVideos = [
    "/videos/1.mp4", 
    "/videos/1.mp4",
    "/videos/1.mp4",
  ];

  return (
    <CoinsProvider>
      <div className="app-container">
        <ParticlesBackground />
        <div className="content">
          <Routes>
            <Route path="/" element={<CoinCounter logoUrl={logo} adVideos={adVideos} />} />
            <Route path="/discount-code" element={<DiscountCode vector={imgVector} />} />
            <Route path="/subscribe" element={<Subscribe />} />
          </Routes>
        </div>
        <div className="sidebar">
          <Link to="/" className="sidebar-item">
            سکه
            <FaCoins />
          </Link>
          <Link to="/discount-code" className="sidebar-item">
            کد تخفیف
            <FaTag />
          </Link>
          <Link to="/subscribe" className="sidebar-item">
            اشتراک
            <FaUser />
          </Link>
        </div>
      </div>
    </CoinsProvider>
  );
};


export default App;
