import React, { createContext, useContext, useState, ReactNode } from "react";

interface CoinsContextType {
  coins: number;
  incrementCoins: (amount: number) => void;
  addCoins: (amount: number) => void;
}

interface CoinsProviderProps {
  children: ReactNode; 
}

const CoinsContext = createContext<CoinsContextType | undefined>(undefined);

export const CoinsProvider: React.FC<CoinsProviderProps> = ({ children }) => {
  const [coins, setCoins] = useState(0);

  const incrementCoins = (amount: number) => {
    setCoins((prevCoins) => prevCoins + amount);
  };

  const addCoins = (amount: number) => {
    setCoins((prevCoins) => prevCoins + amount);
  };

  return (
    <CoinsContext.Provider value={{ coins, incrementCoins, addCoins }}>
      {children}
    </CoinsContext.Provider>
  );
};

export const useCoins = () => {
  const context = useContext(CoinsContext);
  if (!context) {
    throw new Error("useCoins must be used within a CoinsProvider");
  }
  return context;
};
